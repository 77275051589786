import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function GladiatorGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner min_device">
          <GradientEffect />
          <img
            className="slide-bg min_device web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gladiator-img.png`}
            alt="gladiator"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-gladiator.jpg`}
            alt="gladiator"
          />
          <BannerText className="text-holder left top_">
            <SectionHeading>Gladiator Heroes</SectionHeading>
            <address className="developer">Developer: Big Cactus Games</address>
            <Para className="description">
              Gladiator Heroes is a mobile game developed by Big Cactus Games
              and was the first game to test the new Virtua SDK. Working closely
              with the team, we launched an internal beta with limited features,
              quickly expanding to the list of features available today.
              <br /> The first closed beta used some of our key features to
              measure the user behavior, so that we could best improve the
              experience. The next updates are coming soon!
            </Para>
            <div className="buttons w-100">
              <a
                href="https://play.google.com/store/apps/details?id=com.generagames.gladiatorheroes"
                target="_blank"
                id="gladiator_heroes"
                rel="noreferrer"
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android-dark.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div>
            <Para className="purple">Key features:</Para>
            <ul className="flex items-center flex-wrap">
              <li>Shared login</li>
              <li>In-game tournament</li>
              <li>Daily rewards</li>
              <li>Weekly leaderboard</li>
              <li>In-game rewards</li>
              <li>Quests</li>
            </ul>
            <Para className="purple">Beta stats:</Para>
            <ul className="flex items-center flex-wrap gap-60 beta">
              <div className="flex column-direction">
                <li className="">
                  Average minutes per user
                  <div class="progress">
                    <div
                      class="progress-done"
                      data-done="80"
                      style={{ width: "82%" }}
                    >
                      ^82%
                    </div>
                  </div>
                </li>
                <li>
                  Average sessions per user
                  <div class="progress">
                    <div
                      class="progress-done"
                      data-done="60"
                      style={{ width: "30%" }}
                    >
                      ^30%
                    </div>
                  </div>
                </li>
              </div>
              <div className="flex column-direction">
                <li>
                  Day 7 retention
                  <div class="progress">
                    <div
                      class="progress-done"
                      data-done="60"
                      style={{ width: "60%" }}
                    >
                      ^60%
                    </div>
                  </div>
                </li>
                <li>
                  Average revenue per paying daily active user
                  <div class="progress">
                    <div
                      class="progress-done"
                      data-done="60"
                      style={{ width: "25%" }}
                    >
                      ^25%
                    </div>
                  </div>
                </li>
              </div>
            </ul>
            {/* <ul className="flex items-center flex-wrap gap-35">
              <li>Average sessions per user</li>
              <li>Average revenue per paying daily active user</li>
            </ul> */}
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default GladiatorGame;
