import {
  GeneralsIcon,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
} from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function NitroGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro-img.png`}
            alt="nitro"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-nitro.jpg`}
            alt="nitro"
          />
          <BannerText className="text-holder shelby">
            <SectionHeading>Nitro League</SectionHeading>
            <address className="developer">Developer: Nitro League</address>
            <Para className="item">
              Nitro League is a desktop game developed by Nitro League. Nitro
              and Virtua have worked together for a long time and was one of the
              first games to interact with the Virtua ecosystem, prior to the
              development of the SDK. The integration focuses on the players
              being able to race their favorite cars & then bring them to their
              personal space as a center piece of their Gamers Lounge.
            </Para>
            <div className="buttons w-100">
              <a
                href="https://www.nitroleague.com/"
                target="_blank"
                id="nitro_league"
                rel="noreferrer"
                className="btn"
              >
                {/* <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}pc.svg`}
                    alt="android"
                  />
                </span> */}
                <span className="icon" style={{ lineHeight: "0" }}>
                  {GeneralsIcon.pcIcon}
                </span>
                Play On PC
              </a>
            </div>
            <Para className="purple">Key features:</Para>
            <ul className="flex items-center gap-60">
              <li>Shared login</li>
              <li>In-game cars</li>
              <li>Car display pieces</li>
            </ul>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default NitroGame;
